import React, { useState } from 'react';
import Layout from 'components/Layout';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'utils/customHooks';
import DateTimePicker from 'components/inputs/DateTimePicker';
import ContainerBox from 'components/layouts/Container';
import qs from 'query-string';
import fetch from 'utils/fetch';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

const Dashboard = (props) => {
  const { branchId } = qs.parse(props.location.search);
  const classes = useStyles();
  const [notif] = useSnackbar();
  const [dateRange, setDateRange] = useState({});
  const [orderId, setOrderId] = useState(undefined);

  const DownloadButton = (props) => {
    return (
      <Button variant="contained" color="default" component="a" {...props}>
        {props.title}
      </Button>
    );
  };

  function UploadButton(props) {
    const { title, upload, href } = props;
    const [resetInput, setResetInput] = useState(1);
    return (
      <label htmlFor={href}>
        <input
          key={resetInput}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          className={classes.input}
          id={href}
          // multiple
          onChange={(e) => {
            upload(e, href);
            setResetInput((val) => val + 1);
          }}
        />
        <Button
          variant="contained"
          color="default"
          component="span"
          startIcon={<CloudUploadIcon />}
          className={classes.button}
        >
          {title}
        </Button>
      </label>
    );
  }
  const ParamType = {
    supplier_id: 'supplier_id',
    start_date: 'start_date',
    end_date: 'end_date',
    order_id: 'order_id',
  };
  const dashboardOptions = [
    {
      name: 'App Announcements',
      api: `/announcement`,
    },
    {
      name: 'SKU Pricing Upload',
      api: `/price`,
    },
    {
      name: 'Promotions Enable (Invoice + SKU)',
      api: `/promotion/enable`,
      disableDownload: true,
    },
    {
      name: 'Promotions Upload (SKU)',
      api: `/promotion/sku`,
    },
    {
      name: 'Promotions Upload (Invoice)',
      api: `/promotion/invoice`,
    },
    {
      name: 'Promotions Upload (Loyalty)',
      api: `/promotion/loyalty`,
    },
    {
      name: 'Promotions Upload (Customer)',
      api: `/promotion/outlet`,
    },
    {
      name: 'Recommended Products Upload',
      api: `/product-recommendations`,
    },
    {
      name: 'Inventory Upload',
      api: `/inventory`,
    },
    {
      name: 'Order Download',
      api: ``,
      onlyDownload: true,
      params: [ParamType.start_date, ParamType.end_date],
    },
    {
      name: 'Supplier Specs',
      api: `/supplier/specifications`,
    },
    // {
    //   name: 'Invoice Generation',
    //   api: `/invoice`,
    //   onlyDownload: true,
    //   params: [ParamType.start_date, ParamType.end_date],
    // },
    {
      name: 'Invoice Generation',
      api: `/invoice`,
      onlyDownload: true,
      params: [ParamType.start_date, ParamType.end_date, ParamType.order_id],
    },
    // {
    //   name: 'SKU Upload (principal)',
    //   api: `/sku/principal`,
    // },
    // {
    //   name: 'SKU Upload (brand)',
    //   api: `/sku/brand`,
    // },
  ];
  const getApiParams = (option) => {
    let queryString = `?supplier_id=${branchId}`;
    const dateRangeValue = dateRange[option.api];

    if (option.params?.includes(ParamType.start_date) && dateRangeValue) {
      queryString += `&start_date=${dateRangeValue[0]}&end_date=${dateRangeValue[1]}`;
    }
    if (option.params?.includes(ParamType.order_id) && orderId) {
      queryString += `&order_id=${orderId}`;
    }
    return queryString;
  };

  const getApiPathWithQueryParams = (option, actionType, excludePrefix) => {
    const apiPath = option.api;
    let path = `/sbmart${apiPath}/${actionType}`;

    if (!excludePrefix) {
      path = `/api${path}`;
    }
    return `${path}${getApiParams(option)}`;
  };

  const handleFileUpload = async (event, path) => {
    var file = event.target.files[0];
    const data = new FormData();
    data.append('file', file);
    try {
      await fetch({
        method: 'POST',
        url: path,
        data,
      });
      notif('File successfully uploaded', { variant: 'success' });
    } catch (error) {
      // setResetInput((val) => val + 1);
    }
  };

  return (
    <Layout {...props}>
      {branchId == -1 ? (
        <Box m={2}>
          <Typography>
            This page is only available from a Branch. Please select a branch from the branch
            dropdown
          </Typography>
        </Box>
      ) : (
        dashboardOptions.map((o) => {
          return (
            <ContainerBox key={o.name} cols={o.params?.includes(ParamType.order_id) ? 3 : 2}>
              <Typography variant="h6" align="left">
                {o.name}
              </Typography>
              {o.params?.includes(ParamType.order_id) && (
                <TextField
                  id="outlined-textarea"
                  label="Order Id"
                  placeholder="Enter Order Id"
                  variant="outlined"
                  value={orderId}
                  size="small"
                  onChange={(event) => {
                    setOrderId(event.target.value);
                  }}
                />
              )}
              <Box>
                {o.params?.includes(ParamType.start_date) && (
                  <DateTimePicker
                    onChange={(dateRange) =>
                      setDateRange((preDateRange) => ({ ...preDateRange, [o.api]: dateRange }))
                    }
                  />
                )}
                {!o.onlyDownload && (
                  <>
                    <DownloadButton
                      href={getApiPathWithQueryParams(o, 'template')}
                      title="template"
                      className={classes.button}
                      startIcon={<FileCopyIcon />}
                    />
                    <UploadButton
                      startIcon={<CloudUploadIcon />}
                      className={classes.button}
                      title="upload"
                      href={getApiPathWithQueryParams(o, 'upload', true)}
                      upload={handleFileUpload}
                    />
                  </>
                )}
                {!o.disableDownload && (
                  <DownloadButton
                    href={getApiPathWithQueryParams(o, 'download')}
                    title="download"
                    startIcon={<CloudDownloadIcon />}
                    className={classes.button}
                    disabled={
                      o.params?.includes(ParamType.start_date) && dateRange[o.api] === undefined
                      // || (o.params?.includes(ParamType.order_id) && !orderId)
                    }
                  />
                )}
              </Box>
            </ContainerBox>
          );
        })
      )}
    </Layout>
  );
};
export default Dashboard;
